import React from 'react';
import * as styles from './styles.module.scss';
import Image from '../../../ui/image';

const Spinner = () => (
  <div className={styles.spinnerContainer}>
    <Image
      image={{ url: '/images/loader_transparent.gif' }}
      disableGatsbyImage
      imgClassName={styles.spinner}
    />
  </div>
);

export default Spinner;
