import React, { useState } from 'react';

export const useSearchResult = () => {
  const [state, setState] = useState({
    count: 0,
    items: [],
    loading: true,
  });

  return [state, setState];
};
