import React, { useContext } from 'react';
import Image from '../../../ui/image';
import Link from '../../../ui/link';
import Text from '../../../ui/text';
import * as styles from './styles.module.scss';
import { combine } from '../../../../helpers/styles';
import { getCopyWithFallback } from '../../../../helpers/copy';
import { MicrocopyContext } from '../../../../context/microcopy.context';
import { getFormattedDate } from '../../../../helpers/getFormattedDate';
import { useLocale } from '../../../../context/locale.context';

const StoryItem = ({
  slug,
  type,
  articleType,
  date,
  headline,
  image,
  asRecommended,
}) => {
  const { locale } = useLocale();
  const microcopy = useContext(MicrocopyContext);

  const linkPrefix = {
    pressRelease: '/if-magazine/newsroom',
    article: '/if-magazine',
    page: '',
    '': '',
  };
  const link = `${linkPrefix[type]}/${slug}`;

  const localizedArticleType = getCopyWithFallback(
    `articleType.${articleType?.replace(/ /g, '').toLowerCase()}`,
    microcopy,
    ''
  );

  // Spanish localized date is not capitalized
  const getLocalizedDate = () => {
    const formatted = getFormattedDate(date, 'MMMM yyyy', locale);
    return locale === 'es'
      ? formatted.charAt(0).toUpperCase() + formatted.slice(1)
      : formatted;
  };

  return (
    <>
      <Link
        link={{ linkToPage: link }}
        className={combine(styles.item, asRecommended && styles.recommended)}
      >
        <div
          className={combine(
            styles.itemInnerContainer,
            asRecommended && styles.recommended
          )}
        >
          {image && (
            <Image
              image={image}
              className={combine(styles.imageContainer)}
              imgClassName={styles.image}
              disableImageCaption
              focusPoint={image.focusPoint}
              loading={image.loading}
            />
          )}
          <div className={styles.infoContainer}>
            <div className={styles.metadata}>
              <Text
                as="span"
                type="infotext"
                variant="condensed"
                className={styles.articleType}
              >
                {localizedArticleType}
              </Text>

              {articleType && (
                <Text as="span" type="infotext">
                  {` | `}
                </Text>
              )}
              <Text as="span" type="infotext">
                {getLocalizedDate()}
              </Text>
            </div>
            <Text as="h3" className={styles.headline}>
              {headline}
            </Text>
          </div>
        </div>
      </Link>
    </>
  );
};

export default StoryItem;
