import React from 'react';
import Image from '../../../ui/image';
import Text from '../../../ui/text';
import * as styles from './styles.module.scss';
import { combine } from '../../../../helpers/styles';
import Link from '../../../ui/link';

const WinnerItem = ({
  slug,
  image,
  date,
  title,
  subtitle,
  additionalInfo,
  asRow,
  type = 'entry',
}) => {
  return (
    <Link link={{ linkToPage: slug }}>
      <div className={combine(styles.winnerItem, asRow && styles.horizontal)}>
        <Image
          image={image.image}
          className={combine(styles.imageContainer, styles[type])}
          disableImageCaption
          disableGatsbyImage
          focusPoint={image.focusPoint}
          imgClassName={combine(styles.image, styles[type])}
          loading={image.image.loading}
        />
        <div className={styles.textContainer}>
          <div>
            {date && (
              <Text as="span" type="infotext" className={styles.date}>
                {date}
              </Text>
            )}
            <Text as="h3" className={combine(styles.title, styles[type])}>
              {title}
            </Text>
            <p className={styles.subtitle}>{subtitle}</p>
          </div>
          {additionalInfo && (
            <p className={styles.additionalInfo}>{additionalInfo}</p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default WinnerItem;
