// extracted by mini-css-extract-plugin
export var articleType = "styles-module--articleType--14ef8";
export var emptyImageContainer = "styles-module--emptyImageContainer--07c46";
export var headline = "styles-module--headline--21948";
export var image = "styles-module--image--2a90a";
export var imageContainer = "styles-module--imageContainer--6b425";
export var infoContainer = "styles-module--infoContainer--f99a1";
export var item = "styles-module--item--cc302";
export var itemInnerContainer = "styles-module--itemInnerContainer--c376e";
export var metadata = "styles-module--metadata--7da29";
export var recommended = "styles-module--recommended--6d623";