// extracted by mini-css-extract-plugin
export var additionalInfo = "styles-module--additionalInfo--9860c";
export var date = "styles-module--date--63214";
export var horizontal = "styles-module--horizontal--0aaed";
export var image = "styles-module--image--b30af";
export var imageContainer = "styles-module--imageContainer--a0d26";
export var juror = "styles-module--juror--1e0e8";
export var profile = "styles-module--profile--709b5";
export var subtitle = "styles-module--subtitle--591b5";
export var textContainer = "styles-module--textContainer--73374";
export var title = "styles-module--title--31a98";
export var winnerItem = "styles-module--winnerItem--607c8";