import React from 'react';
import Layout from '../../modules/layout';
import SearchResults from '../../components/searchResultsRework';

const SearchPage = () => (
  <Layout
    title={'title.searchPage'}
    description={'desc.searchPage'}
    slug={'search'}
  >
    <SearchResults />
  </Layout>
);
export default SearchPage;
